import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormCheck = lazy(
  () => import("./form-check-0rbU8Bcu.js").then((module) => ({
    default: module.FormCheck
  }))
);
const LazyCheck = lazy(
  () => import("./check-box-fssyWL5F.js").then((n) => n.b).then((module) => ({
    default: module.CheckBox
  }))
);
function FormCheck({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormCheck, { ...props });
}
const useFormCheckContent = () => {
  return useMemo(
    () => ({
      formCheck: {
        component: FormCheck
      }
    }),
    []
  );
};
export {
  LazyCheck as CheckBox,
  LazyFormCheck as FormCheck,
  useFormCheckContent
};
